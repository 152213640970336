import { useMutation, useQueryClient } from 'react-query';
import { waitForConfirmation } from '../../../sci-ui-components/ConfirmationDialog';
import { CollectibleType } from '../../../sci-ui-components/types/collectibleType';
import {
  deleteSavedSearch,
  saveSearch,
  updateSavedSearch,
  updateSavedSearches,
  DeleteSavedSearchParams,
  SaveSearchParams,
  UpdateSavedSearchParams,
  UpdateSavedSearchesParam,
} from '../../../services/sciApi/search/savedSearches';
import { showError, showSuccess } from '../../../services/toaster';
import savedSearchCategoryFromApi from '../../../services/sciApi/search/transformers/savedSearchCategoryFromApi';
import useAnalytics from '../../analytics/useAnalytics';
import { ApiSavedSearchType } from '../../../services/sciApi/types';
import { keyPrefix as savedSearchesKeyPrefix, Key as SavedSearchesKey, SavedSearchesData } from './useSavedSearches';
import { featureSavedSearchesKeyPrefix } from './useFeatureSavedSearches';

const keyPrefix = 'saved-searches';

export default function useSavedSearchesMutations({
  collectibleType,
  savedSearchType,
}: {
  collectibleType: CollectibleType;
  savedSearchType?: ApiSavedSearchType;
}) {
  const queryClient = useQueryClient();
  const { trackEvent } = useAnalytics();

  const fetchSavedSearches = (savedSearchType?: ApiSavedSearchType) => {
    if (savedSearchType) {
      queryClient.refetchQueries([featureSavedSearchesKeyPrefix, savedSearchType]);
    } else {
      queryClient.refetchQueries([savedSearchesKeyPrefix]);
    }
  };
  const create = useMutation({
    mutationFn: async ({
      categoryId,
      ...params
    }: SaveSearchParams & {
      categoryId: string | null;
    }) => {
      const saved = await saveSearch(params);
      if (categoryId) {
        await updateSavedSearch({
          id: saved.id,
          categoryId,
        });
        return {
          ...saved,
          category: savedSearchCategoryFromApi(categoryId),
          savedSearchType: params.savedSearchType,
        };
      }
      return { ...saved, savedSearchType: params.savedSearchType };
    },
    mutationKey: [keyPrefix, 'create'],
    onSuccess: ({ title, collectibleIds, savedSearchType }) => {
      fetchSavedSearches(savedSearchType);
      showSuccess({
        message: `Saved ${title}`,
      });
      trackEvent({
        eventName: 'SEARCH_SAVED_ADDED',
        collectibleIds,
        collectibleType,
      });
    },
    onError: (_, { title }) => {
      showError({
        message: `Failed to save ${title}. Please try again.`,
      });
    },
  });

  const update = useMutation({
    mutationFn: (params: UpdateSavedSearchParams) => updateSavedSearch(params),
    mutationKey: [keyPrefix, 'update'],
    onSuccess: (_, { categoryId }) => {
      fetchSavedSearches(savedSearchType);
      showSuccess({
        message: `Updated saved search`,
      });
      if (categoryId) {
        trackEvent({
          eventName: 'SEARCH_SAVED_MOVED_CATEGORY',
          collectibleType,
        });
      } else {
        trackEvent({
          eventName: 'SEARCH_SAVED_UPDATED',
          collectibleType,
        });
      }
    },
    onError: () => {
      showError({
        message: 'Failed to update saved search. Please try again.',
      });
    },
  });

  const remove = useMutation({
    mutationFn: (params: DeleteSavedSearchParams) => deleteSavedSearch(params),
    mutationKey: [keyPrefix, 'remove'],
    onSuccess: () => {
      fetchSavedSearches(savedSearchType);
      showSuccess({
        message: `Deleted saved search`,
      });
      trackEvent({
        eventName: 'SEARCH_SAVED_REMOVED',
        collectibleType,
      });
    },
    onError: () => {
      showError({
        message: 'Failed to remove from Favorites. Please try again.',
      });
    },
  });

  const removeWithConfirmation = async (params: DeleteSavedSearchParams) => {
    const confirmed = await waitForConfirmation({
      title: 'Delete',
      description: `Are you sure you want to delete this saved search?`,
    });
    if (confirmed) {
      await remove.mutateAsync(params);
    } else {
      trackEvent({
        eventName: 'SEARCH_SAVED_DELETE_CANCELLED',
        collectibleType,
      });
    }
  };

  const reorder = useMutation({
    mutationFn: (params: UpdateSavedSearchesParam[]) => updateSavedSearches(params),
    mutationKey: [keyPrefix, 'reorder'],
    onMutate: (params) => {
      // update Saved Searches order optimistically
      const currentSavedSearches = queryClient.getQueryData<SavedSearchesData>([
        savedSearchesKeyPrefix,
        { collectibleType },
      ] as SavedSearchesKey);
      if (!currentSavedSearches) {
        return;
      }
      const orderMap = params.reduce<Map<number, number | undefined>>((acc, { id, order }) => {
        acc.set(id, order);
        return acc;
      }, new Map());
      const optimisticSavedSearches = currentSavedSearches.map((search) => {
        if (orderMap.has(search.id)) {
          const order = orderMap.get(search.id)!;
          return {
            ...search,
            order,
          };
        }
        return search;
      });
      queryClient.setQueryData<SavedSearchesData>(
        [savedSearchesKeyPrefix, { collectibleType }] as SavedSearchesKey,
        optimisticSavedSearches
      );
    },
    onSuccess: () => {
      fetchSavedSearches(savedSearchType);
      trackEvent({
        eventName: 'SEARCH_SAVED_REORDERED',
        collectibleType,
      });
    },
    onError: () => {
      showError({
        message: 'Failed to reorder. Please try again.',
      });
    },
  });

  return {
    create,
    update,
    remove,
    removeWithConfirmation,
    reorder,
  };
}
