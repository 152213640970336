import * as Sentry from '@sentry/nextjs';
import qs from 'qs';
import { CollectibleType } from '../../../sci-ui-components/types/collectibleType';
import authenticatedFetchFromSciApi from '../authenticatedFetchFromSciApi';
import { ApiSavedSearchType, ApiSavedSearch } from '../types';
import { getNumericArrayParam } from '../../../utils/queryString';
import { maxSelectableCollectibles } from '../../../constants';
import { setItem } from '../../../utils/localStorage';
import openSearch, { OpenSearchParams } from './openSearch';
import savedSearchFromApi from './transformers/savedSearchFromApi';
import savedSearchCategoryFromApi, { makeSavedSearchCategoryId } from './transformers/savedSearchCategoryFromApi';
import { featureSavedSearchFromApi } from './transformers/featureSavedSearchFromApi';
import { SavedSearchCategory } from 'sci-ui-components/types/search';

export const SAVED_SEARCH_CATEGORY_PREFIX = 'sci_scat';

export const getStorageCategoryKey = (savedSearchType?: ApiSavedSearchType) => {
  if (!savedSearchType) return SAVED_SEARCH_CATEGORY_PREFIX;

  return `${SAVED_SEARCH_CATEGORY_PREFIX}_${savedSearchType.toLocaleLowerCase()}`;
};

const apiSearchTypesByCollectibleType: Record<CollectibleType, ApiSavedSearchType> = {
  'sports-card': 'PLAYER_CHARTS',
  'sealed-wax-card': 'SEALED_WAX_CHARTS',
};

export type SavedSearchFeature = 'ratios';

export const apiSearchTypesByFeature: Record<SavedSearchFeature, ApiSavedSearchType[]> = {
  ratios: ['GRADE_RATIOS', 'VARIATION_RATIOS', 'PLAYER_RATIOS'],
};

export interface GetSavedSearchesParams {
  collectibleType: CollectibleType;
}

export async function getSavedSearch(apiSearchType: ApiSavedSearchType, signal?: AbortSignal) {
  return authenticatedFetchFromSciApi<ApiSavedSearch[]>(`/savedSearch/${apiSearchType}`, {
    method: 'GET',
    signal,
  });
}

export async function getFeatureSavedSearch(
  {
    collectibleType,
    apiSearchType,
    searchPrefix,
  }: { collectibleType: CollectibleType; apiSearchType: ApiSavedSearchType; searchPrefix?: string },
  signal?: AbortSignal
) {
  const searches = await getSavedSearch(apiSearchType, signal);

  return featureSavedSearchFromApi(searches, collectibleType, searchPrefix);
}

export async function getSavedSearches({ collectibleType }: GetSavedSearchesParams, signal?: AbortSignal) {
  const apiSearchType = apiSearchTypesByCollectibleType[collectibleType];
  const apiSavedSearches = await getSavedSearch(apiSearchType, signal);

  const apiSavedSearchesWithCollectibleIds = await Promise.all(
    apiSavedSearches.map<
      Promise<{
        apiSavedSearch: ApiSavedSearch;
        collectibleIds: number[];
      }>
    >(async (apiSavedSearch) => {
      const query = qs.parse(apiSavedSearch.query, {
        ignoreQueryPrefix: true,
        comma: true,
      }) as {
        cid?: string | string[];
        players?: string | string[];
        sets?: string | string[];
        set_variations?: string | string[];
        years?: string | string[];
        grades?: string | string[];
        sports?: string | string[];
        set_name_years?: string | string[];
        box_types?: string | string[];
      };
      if (query.cid) {
        // NOTE: query has collectible ids
        const collectibleIds = Array.isArray(query.cid) ? query.cid.map((cid) => Number(cid)) : [Number(query.cid)];
        return {
          apiSavedSearch,
          collectibleIds,
        };
      } else {
        // NOTE: query has filters => need to find ids
        const searchParams: OpenSearchParams = {
          collectibleType,
          limit: maxSelectableCollectibles,
          offset: 0,
          query: '*',
          playerIds: getNumericArrayParam(query, 'players', undefined),
          cardSetIds: getNumericArrayParam(query, 'sets', undefined),
          cardSetVariationIds: getNumericArrayParam(query, 'set_variations', undefined),
          cardSetYears: getNumericArrayParam(query, 'years', undefined),
          cardGradeIds: getNumericArrayParam(query, 'grades', undefined),
          sportIds: getNumericArrayParam(query, 'sports', undefined),
          sealedWaxBoxTypeIds: getNumericArrayParam(query, 'box_types', undefined),
        };
        try {
          const searchResult = await openSearch(searchParams, signal);
          const collectibleIds = searchResult.items.map((i) => i.id);
          return {
            apiSavedSearch,
            collectibleIds,
          };
        } catch (err) {
          Sentry.captureException(err);
          console.error(
            `Failed to fetch collectible ids for saved search params: ${JSON.stringify(searchParams)}`,
            err
          );
          return {
            apiSavedSearch,
            collectibleIds: [],
          };
        }
      }
    })
  );

  return apiSavedSearchesWithCollectibleIds.map(({ apiSavedSearch, collectibleIds }, index) =>
    savedSearchFromApi(apiSavedSearch, {
      collectibleIds,
      collectibleType,
      defaultOrder: apiSavedSearchesWithCollectibleIds.length + index,
    })
  );
}

const defaultOrder = 9999;

export interface SaveSearchParams {
  collectibleType: CollectibleType;
  title: string;
  collectibleIds: number[];
  savedSearchType?: ApiSavedSearchType;
  savedSearchQuery?: string;
}

export async function saveSearch({
  collectibleType,
  title,
  collectibleIds,
  savedSearchType,
  savedSearchQuery = '',
}: SaveSearchParams & { savedSearchType?: ApiSavedSearchType; savedSearchQuery?: string }) {
  const type = savedSearchType ? savedSearchType : apiSearchTypesByCollectibleType[collectibleType];
  const query = savedSearchType ? savedSearchQuery : `?cid=${collectibleIds.join(',')}`;

  const apiItem = await authenticatedFetchFromSciApi<ApiSavedSearch>('/savedSearch', {
    method: 'POST',
    body: JSON.stringify({
      query,
      search: query,
      title,
      type,
    }),
  });

  return savedSearchFromApi(apiItem, {
    collectibleIds: savedSearchType ? [] : collectibleIds,
    collectibleType,
    defaultOrder,
  });
}

export interface UpdateSavedSearchParams {
  id: number;
  title?: string;
  categoryId?: string | null;
}

export async function updateSavedSearch({ id, title, categoryId }: UpdateSavedSearchParams) {
  await authenticatedFetchFromSciApi<void>(
    `/savedSearch${qs.stringify(
      {
        id,
      },
      { addQueryPrefix: true, skipNulls: true }
    )}`,
    {
      method: 'PUT',
      body: JSON.stringify({
        title,
        category: categoryId,
      }),
    }
  );
}

export interface UpdateSavedSearchesParam {
  id: number;
  order?: number;
  categoryId?: string;
}

export async function updateSavedSearches(params: UpdateSavedSearchesParam[]) {
  await authenticatedFetchFromSciApi<void>('/savedSearch/bulk', {
    method: 'PUT',
    body: JSON.stringify({
      searches: params.map(({ id, categoryId, order }) => ({
        id,
        order,
        category: categoryId,
      })),
    }),
  });
}

export interface DeleteSavedSearchParams {
  id: number;
}

export async function deleteSavedSearch({ id }: DeleteSavedSearchParams) {
  await authenticatedFetchFromSciApi<void>(`/savedSearch/${id}`, {
    method: 'DELETE',
  });
}

export interface GetLocalSavedSearchCategoriesParams {
  collectibleType: CollectibleType;
  savedSearchType?: ApiSavedSearchType;
}

// NOTE: Api currently does not have saved search category entity so there is no way to create a new category there without creating a saved search for it.
// so for now, these categories are saved locally only.
const savedSearchCategoriesStorageKey = SAVED_SEARCH_CATEGORY_PREFIX;

export function getLocalSavedSearchCategories({
  collectibleType,
  savedSearchType,
}: GetLocalSavedSearchCategoriesParams) {
  const savedCategories = getSavedSearchCategoriesFromLocalStorage(getStorageCategoryKey(savedSearchType));
  const categoryIds: string[] = savedCategories[collectibleType] ?? [];
  return categoryIds
    .map((categoryId: string) => savedSearchCategoryFromApi(categoryId))
    .filter((c): c is SavedSearchCategory => !!c);
}

export interface CreateLocalSavedSearchCategoryParams {
  collectibleType: CollectibleType;
  name: string;
  savedSearchType?: ApiSavedSearchType;
}

export function createLocalSavedSearchCategory({
  collectibleType,
  name,
  savedSearchType,
}: CreateLocalSavedSearchCategoryParams): SavedSearchCategory {
  const storageKey = getStorageCategoryKey(savedSearchType);
  const savedCategories = getSavedSearchCategoriesFromLocalStorage(storageKey);
  const id = makeSavedSearchCategoryId(name);
  const newCategories = [...(savedCategories[collectibleType] ?? []), id];

  setSavedSearchCategoriesInLocalStorage(
    {
      ...savedCategories,
      [collectibleType]: newCategories,
    },
    storageKey
  );
  return {
    id,
    name,
  };
}

export interface DeleteLocalSavedSearchCategoryParams {
  collectibleType: CollectibleType;
  categoryId: string;
  savedSearchType?: ApiSavedSearchType;
}

export function deleteLocalSavedSearchCategory({
  collectibleType,
  categoryId,
  savedSearchType,
}: DeleteLocalSavedSearchCategoryParams) {
  const storageKey = getStorageCategoryKey(savedSearchType);
  const savedCategories = getSavedSearchCategoriesFromLocalStorage(storageKey);
  const newCategories = (savedCategories[collectibleType] ?? []).filter((c: string) => c !== categoryId);
  setSavedSearchCategoriesInLocalStorage(
    {
      ...savedCategories,
      [collectibleType]: newCategories,
    },
    storageKey
  );
}

export interface RenameLocalSavedSearchCategory {
  collectibleType: CollectibleType;
  id: string;
  name: string;
  savedSearchType?: ApiSavedSearchType;
}

export function renameLocalSavedSearchCategory({
  collectibleType,
  id,
  name,
  savedSearchType,
}: RenameLocalSavedSearchCategory): SavedSearchCategory {
  const storageKey = getStorageCategoryKey(savedSearchType);
  const savedCategories = getSavedSearchCategoriesFromLocalStorage(storageKey);
  const newId = makeSavedSearchCategoryId(name);
  const newCategories = (savedCategories[collectibleType] ?? []).map((catId: string) => (catId === id ? newId : id));

  setSavedSearchCategoriesInLocalStorage(
    {
      ...savedCategories,
      [collectibleType]: newCategories,
    },
    storageKey
  );
  return {
    id: newId,
    name,
  };
}

function getSavedSearchCategoriesFromLocalStorage(
  storageKey: string = savedSearchCategoriesStorageKey
): Partial<Record<CollectibleType, string[]>> {
  const storedItemString = localStorage.getItem(storageKey) || '{}';
  return JSON.parse(storedItemString);
}

function setSavedSearchCategoriesInLocalStorage(
  categories: Partial<Record<CollectibleType, string[]>>,
  storageKey: string = savedSearchCategoriesStorageKey
) {
  setItem(storageKey, categories);
}
